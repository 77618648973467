import React, { useEffect, useRef, useState } from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { connectAccount } from '@silkpwa/module/react-component/connect-account';
import { classes } from '@silkpwa/module/util/classes';
import { setSessionStorageData, getSessionStorageData } from 'ui/util/session-storage';
import {
    cartIdVar,
    customerEmailVar,
    customerPhoneVar,
    customerVar,
    isGuestVar,
    isLoggedInVar,
    SetIsExplicitGuest,
    stepsCompletedVar,
} from 'ui/page/checkout-page/checkout-state';
import { GET_CUSTOMER } from 'graphql/customer';
import { GENERATE_CUSTOMER_TOKEN } from 'graphql/login/login';
import { ASSIGN_CUSTOMER_TO_GUEST_CART, SET_GUEST_EMAIL_ON_CART } from 'graphql/cart/mutations';
import { GET_CUSTOMER_PUBLIC_DETAILS } from 'graphql/customer/query';
import fStyles from 'ui/component/checkout/styles/form-style.css';
import { GoogleRecaptchaWidget } from '@silkpwa/module/google-recaptcha';
import ReCAPTCHA from 'react-google-recaptcha';
import { LoadingImage } from 'ui/component/loading-image';
import { ForgotPassword } from './forgot-password';
import styles from './style.css';

interface ILoginFormProps {
    accountActions: any;
    updateCart: (cartId: string | null) => Promise<void>;
}

// TODO: need to refactor.
const LoginForm: React.FC<ILoginFormProps> = ({
    accountActions,
    updateCart,
}) => {
    const t = usePhraseTranslater();
    const cartId = useReactiveVar(cartIdVar);
    const customer = useReactiveVar(customerVar);
    const recaptchaRef = useRef<ReCAPTCHA>();
    const passwordRef = useRef<HTMLInputElement>(null);
    const email = useReactiveVar(customerEmailVar);
    const stepsCompleted = useReactiveVar(stepsCompletedVar);
    const [loginFormLoading, setLoginFormLoading] = useState(false);
    const [error, setError] = useState('');
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [user, setUser] = useState({ email, password: '' });

    const [setGuestEmailOnCart] = useMutation(SET_GUEST_EMAIL_ON_CART);
    const [assignCustomerToGuestCart] = useMutation(ASSIGN_CUSTOMER_TO_GUEST_CART, {
        onError: () => {
            setError(t('Something went wrong please try again.'));
        },
        onCompleted: (data) => {
            if (data?.assignCustomerToGuestCart !== undefined) {
                setSessionStorageData('cartId', data.assignCustomerToGuestCart.id);
            }
        },
    });
    const [getCustomer] = useLazyQuery(GET_CUSTOMER, {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-and-network',
        onError: () => {
            setError(t('Something went wrong please try again.'));
        },
        onCompleted: (data) => {
            if (data.customer) {
                customerVar(data.customer);
            }
        },
    });
    const [generateCustomerToken, { loading }] = useMutation(GENERATE_CUSTOMER_TOKEN, {
        variables: { email: user.email, password: user.password },
        onError: () => {
            setError(t('Something went wrong please try again.'));
        },
        onCompleted: (data) => {
            if (data?.generateCustomerToken?.token !== undefined) {
                setSessionStorageData('customerToken', data.generateCustomerToken.token);
                isLoggedInVar(true);
            }
        },
    });
    const [getCustomerPublicDetails] = useLazyQuery(GET_CUSTOMER_PUBLIC_DETAILS, {
        variables: {
            email,
        },
    });

    const getWelcomeMessage = async (): Promise<string> => {
        const { data } = await getCustomerPublicDetails();
        // @ts-ignore
        const customerNumber = data?.getCustomerPublicDetails?.phone || '';
        customerPhoneVar(customerNumber);
        // @ts-ignore
        const welcomeMessageFormat = data?.getCustomerPublicDetails?.welcome_message;

        if (welcomeMessageFormat && email) {
            return welcomeMessageFormat.replace('{{EMAIL}}', email);
        }

        return '';
    };
    const [welcomeMessage, setWelcomeMessage] = useState<string>('');

    const triggerSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (recaptchaRef.current) {
            recaptchaRef.current.execute(); // Execute ReCaptcha
        }
    };

    const handleSubmit = async () => {
        try {
            const userInfo = {
                propertyCode: '',
                usernameWithoutCode: user.email,
                password: user.password || passwordRef?.current?.value,
                redirectAfterLoggedIn: false,
            };
            if (userInfo.usernameWithoutCode && userInfo.password) {
                await generateCustomerToken();
                await (async () => {
                    const data = await assignCustomerToGuestCart({
                        variables: { cart_id: cartId },
                    });
                    let guestToCustomerCartId = getSessionStorageData('cartId', true);
                    // @ts-ignore
                    if (data?.assignCustomerToGuestCart?.id) {
                        // @ts-ignore
                        guestToCustomerCartId = data?.assignCustomerToGuestCart?.id;
                        setSessionStorageData('cartId', guestToCustomerCartId || '');
                    }
                    await updateCart(guestToCustomerCartId);
                    await getCustomer();
                })();

                await accountActions.logIn(
                    userInfo.propertyCode,
                    userInfo.usernameWithoutCode,
                    userInfo.password,
                    userInfo.redirectAfterLoggedIn,
                );
            } else {
                setError(t('Something went wrong please try again.'));
            }
        } catch (e) {
            setError(e.response.message);
        }
    };
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUser({ ...user, [event.target.name]: event.target.value });
    };
    const handleExplicitGuest = async () => {
        // TODO: Move setGuestEmailOnCart to HOC to reduce code footprint
        await setGuestEmailOnCart({
            variables: {
                input: {
                    cart_id: cartId,
                    email: user.email,
                },
            },
            onError: (error) => {
                setError(error.message);
            },
            onCompleted: () => {
                SetIsExplicitGuest(true);
                stepsCompletedVar({ ...stepsCompleted, customer: true });
            },
        });
    };
    const toggleForgotPassword = () => {
        setShowForgotPassword(prevState => !prevState);
    };
    const handleResetPassword = () => {
        toggleForgotPassword();
    };
    const cancelLogin = () => {
        isLoggedInVar(false);
        customerEmailVar('');
        isGuestVar(false);
    };
    useEffect(() => {
        if (customer) {
            stepsCompletedVar({ ...stepsCompleted, customer: true });
        }
    }, [customer]);

    useEffect(() => {
        setLoginFormLoading(true);
        const fetchWelcomeMessage = async () => {
            const message = await getWelcomeMessage();
            setWelcomeMessage(message);
        };
        fetchWelcomeMessage().then(() => setLoginFormLoading(false));
    }, []);
    return (
        <>
            {!showForgotPassword && (
                <>
                    {loginFormLoading && (
                        <div className={styles.loadingWrapper}>
                            <LoadingImage />
                        </div>
                    )}
                    {!loginFormLoading && (
                        <form onSubmit={triggerSubmit} className={styles.loginForm}>
                            <button type="button" className={styles.closeButton} onClick={cancelLogin}>
                                <i className="fa-solid fa-xmark" />
                            </button>
                            {error && <div className="error">{t(error)}</div>}
                            <div className={classes(fStyles.formHeader)}>
                                <div className={fStyles.formTitle}>{t('Welcome Back!')}</div>
                                <div className={fStyles.formDescription}>{welcomeMessage}</div>
                            </div>
                            <div className={classes(fStyles.formField)}>
                                <input
                                    type="password"
                                    id="password"
                                    name="password"
                                    value={user.password}
                                    onChange={onChange}
                                    placeholder="password"
                                    ref={passwordRef}
                                />
                            </div>
                            <div style={{ zIndex: -1, height: 0 }}>
                                <GoogleRecaptchaWidget
                                    form="login"
                                    size="invisible"
                                    submit={handleSubmit}
                                    recaptchaRef={recaptchaRef}
                                />
                            </div>
                            <div className={classes(fStyles.formField)}>
                                <button
                                    data-action="checkout-method-login"
                                    type="submit"
                                    className={fStyles.checkoutButton}
                                    disabled={loading}
                                >
                                    {t('Sign In')}
                                </button>
                            </div>
                            <div className={classes(fStyles.formField)}>
                                <button className={fStyles.smallLink} type="button" onClick={handleResetPassword}>
                                    Forgot Password
                                </button>
                            </div>
                            <div className={classes(fStyles.formField, styles.continueAsGuest)}>
                                {email && (
                                    <button className={fStyles.arrowLink} type="button" onClick={handleExplicitGuest}>
                                        Continue As Guest
                                        <i className="fa-solid fa-arrow-right-long" />
                                    </button>
                                )}
                            </div>
                        </form>
                    )}
                </>
            )}
            {showForgotPassword && (
                <div className={styles.forgotPasswordModal}>
                    <button type="button" className={styles.closeButton} onClick={toggleForgotPassword}>
                        <i className="fa-solid fa-xmark" />
                    </button>
                    <ForgotPassword updateCart={updateCart} />
                </div>
            )}
        </>
    );
};

const ConnectedLoginForm = connectAccount(LoginForm);

export { ConnectedLoginForm as LoginForm };
