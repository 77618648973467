import { gql, TypedDocumentNode } from '@apollo/client';

interface ICustomerToken {
    token: string;
}

interface IGenerateCustomerToken {
    generateCustomerToken: ICustomerToken;
}

export const GENERATE_CUSTOMER_TOKEN: TypedDocumentNode<IGenerateCustomerToken> = gql`
    mutation GENERATE_CUSTOMER_TOKEN(
        $email: String!,
        $password: String!
    ) {
        generateCustomerToken(
            email: $email,
            password: $password
        ) {
            token
        }
    }
`;

export const SEND_CUSTOMER_OTP: TypedDocumentNode = gql`
    mutation sendCustomerOtp($email: String!, $redirect_path: String){
        sendCustomerOtp(email: $email, redirect_path: $redirect_path)
    }
`;

export const VERIFY_CUSTOMER_OTP: TypedDocumentNode = gql`
    mutation VERIFY_CUSTOMER_OTP($email: String!, $otp: String!){
        verifyCustomerOtp(email: $email, otp: $otp){
            token
            redirect_path
        }
    }
`;
