import React, { useEffect, useRef, useState } from 'react';
import { classes } from '@silkpwa/module/util/classes';
import ReCAPTCHA from 'react-google-recaptcha';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import {
    cartIdVar, configVar, customerEmailVar, customerVar, isLoggedInVar,
} from 'ui/page/checkout-page/checkout-state';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { SEND_CUSTOMER_OTP, VERIFY_CUSTOMER_OTP } from 'graphql/login/login';
import fStyles from 'ui/component/checkout/styles/form-style.css';
import { getSessionStorageData, setSessionStorageData } from 'ui/util/session-storage';
import { ASSIGN_CUSTOMER_TO_GUEST_CART } from 'graphql/cart/mutations';
import { connectAccount } from '@silkpwa/module/react-component/connect-account';
import { GET_CUSTOMER } from 'graphql/customer';
import { VerifyCustomerOtpResponse, VerifyCustomerOtpVariables } from './login-data';
import styles from './style.css';
import CountdownTimer from './count-down-timer';


interface ILoginFormProps {
    accountActions: any;
    updateCart: (cartId: string) => Promise<void>;
}
const LoginWithOtp: React.FC<ILoginFormProps> = ({
    accountActions,
    updateCart,
}) => {
    const cartId = useReactiveVar(cartIdVar);
    const t = usePhraseTranslater();
    const config = useReactiveVar(configVar);
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const email = useReactiveVar(customerEmailVar);
    const [showSuccessMessage, setShowSuccessMessage] = useState('');
    const [error, setError] = useState('');
    const [otpData, setOtpData] = useState({
        otp: '',
        error: '',
    });
    const [sendCustomerOtp] = useMutation(SEND_CUSTOMER_OTP, {
        variables: { email },
        onError: (error) => {
            setError(error.message || t('Something went wrong please try again.'));
            setShowSuccessMessage('');
        },
        onCompleted: (data) => {
            if (data?.sendCustomerOtp !== '') {
                setError('');
                setShowSuccessMessage(data?.sendCustomerOtp);
            }
        },
    });
    const [verifyCustomerOtp, { loading }] = useMutation<VerifyCustomerOtpResponse,
    VerifyCustomerOtpVariables>(VERIFY_CUSTOMER_OTP, {
        variables: { email, otp: otpData.otp },
        onError: (error) => {
            setError(error.message || t('Something went wrong please try again.'));
        },
        onCompleted: (data) => {
            if (data?.verifyCustomerOtp?.token !== undefined) {
                setSessionStorageData('customerToken', data.verifyCustomerOtp.token);
                isLoggedInVar(true);
            }
        },
    });
    const [assignCustomerToGuestCart] = useMutation(ASSIGN_CUSTOMER_TO_GUEST_CART, {
        onError: () => {
            setError(t('Something went wrong please try again.'));
        },
        onCompleted: (data) => {
            if (data?.assignCustomerToGuestCart !== undefined) {
                setSessionStorageData('cartId', data.assignCustomerToGuestCart.id);
            }
        },
    });
    const [getCustomer] = useLazyQuery(GET_CUSTOMER, {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-and-network',
        onError: () => {
            setError(t('Something went wrong please try again.'));
        },
        onCompleted: (data) => {
            if (data.customer) {
                customerVar(data.customer);
            }
        },
    });
    const triggerSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = { otp: '', error: '' };
        const userOtp = otpData?.otp || '';
        if (!userOtp) {
            // @ts-ignore
            formData.error = 'OTP is required';
        } else {
            otpData.error = '';
        }
        formData.otp = userOtp;
        setOtpData(formData);
        await verifyCustomerOtp();
        await (async () => {
            const data = await assignCustomerToGuestCart({
                variables: { cart_id: cartId },
            });
            let guestToCustomerCartId = getSessionStorageData('cartId', true);
            // @ts-ignore
            if (data?.assignCustomerToGuestCart?.id) {
                // @ts-ignore
                guestToCustomerCartId = data?.assignCustomerToGuestCart?.id;
                setSessionStorageData('cartId', guestToCustomerCartId || '');
            }
            await updateCart(guestToCustomerCartId);
            await getCustomer();
        })();
        await accountActions.setAuth(
            { headers: { Authorization: `Bearer ${getSessionStorageData('customerToken')}` } },
            false,
        );
    };
    const handleInputChange = (e: any) => {
        const { value } = e.target;
        setOtpData({
            error: '',
            otp: value,
        });
    };
    useEffect(() => {
        sendCustomerOtp();
    }, [email]);

    return (
        <>
            <form onSubmit={triggerSubmit} className={styles.resetPasswordForm}>
                <div className={classes(fStyles.formHeader)}>
                    <div className={fStyles.formTitle}>{t('Login With OTP')}</div>
                </div>
                {error && <div className={styles.loginOtpError}>{t(error)}</div>}
                { showSuccessMessage && (
                    <div className={styles.success}>
                        {t(showSuccessMessage)}
                    </div>
                )}
                <span className={styles.instructionMessage}>{t('Please enter the code below.')}</span>
                <div className={classes(fStyles.formField)}>
                    <input
                        type="text"
                        id="otp"
                        name="otp"
                        value={otpData.otp}
                        onChange={handleInputChange}
                        placeholder={t('Enter OTP')}
                    />
                    {otpData.error && <p style={{ color: 'red' }}>{otpData.error}</p>}
                </div>
                {config?.recaptcha_sitekey && (
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={config?.recaptcha_sitekey}
                        size="invisible"
                    />
                )}
                { showSuccessMessage && <CountdownTimer onResendOTP={sendCustomerOtp} />}
                <div className={classes(fStyles.formField)}>
                    <button disabled={loading} type="submit" className={fStyles.checkoutButton}>
                        {t('Sign In')}
                    </button>
                </div>
            </form>
        </>
    );
};
const ConnectedLoginOtp = connectAccount(LoginWithOtp);

export { ConnectedLoginOtp as LoginWithOtp };
