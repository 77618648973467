import React, { useEffect, useState } from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

type CountdownProps = {
    onResendOTP: () => void;
};

const CountdownTimer: React.FC<CountdownProps> = ({ onResendOTP }) => {
    const t = usePhraseTranslater();
    const [secondsRemaining, setSecondsRemaining] = useState<number>(20); // 2 minutes
    const [showResendButton, setShowResendButton] = useState(false);

    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    };

    useEffect(() => {
        if (secondsRemaining > 0) {
            const interval = setInterval(() => {
                setSecondsRemaining(prev => prev - 1);
            }, 1000);

            return () => clearInterval(interval);
        }
        setShowResendButton(true);
        return () => { /* do nothing */ };
    }, [secondsRemaining]);
    const handleResendOTP = async () => {
        try {
            onResendOTP();
            setSecondsRemaining(20);
            setShowResendButton(false);
        } catch (err) {
            /* empty dummy */
        }
    };

    return (
        <div>
            <div>
                {!showResendButton && (
                    <p>
                        {`Resend OTP In : ${formatTime(secondsRemaining)}`}
                    </p>
                )}
            </div>

            {showResendButton && (
                <p role="presentation" onClick={handleResendOTP} className={styles.confirmation}>
                    {t('If you still haven\'t received it, click here to resend.')}
                </p>
            )}
        </div>
    );
};

export default CountdownTimer;
