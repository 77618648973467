import React, { useState, useRef } from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import fStyles from 'ui/component/checkout/styles/form-style.css';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { LoginWithLink } from 'ui/component/log-in-form/login-with-link/login-with-link';
import { LoginWithOtp } from 'ui/component/log-in-form/login-with-otp';
import { useReactiveVar, useMutation } from '@apollo/client';
import { customerPhoneVar, configVar } from 'ui/page/checkout-page/checkout-state';
import { REQUEST_RESET_PASSWORD } from 'graphql/customer/customer';
import ReCAPTCHA from 'react-google-recaptcha';
import styles from './style.css';

class ILoginFormProps {
    updateCart: ((cartId: string | null) => Promise<void>) | undefined;
}

export const ForgotPassword: React.FC<ILoginFormProps> = ({ updateCart }) => {
    const t = usePhraseTranslater();
    const [showLoginWithOtp, setLoginWithOtp] = useState(false);
    const [showLoginWithLink, setLoginWithLink] = useState(false);
    const customerPhoneNumber = useReactiveVar(customerPhoneVar);
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const config = useReactiveVar(configVar);
    const [email, setEmail] = useState('');


    let isLoginWithOtpEnabled = false;
    let isLoginWithLinkEnabled = false;
    const appConfig = useConfig();
    if (appConfig.extension_attributes) {
        isLoginWithOtpEnabled = appConfig.extension_attributes.auth_settings_email_otp_enabled || false;
        isLoginWithLinkEnabled = appConfig.extension_attributes.auth_settings_email_link_enabled || false;
    }
    const getLoginWithMessage = () => {
        if (!isLoginWithOtpEnabled || !customerPhoneNumber) {
            return t('Log in via a link sent to your registered email address.');
        }
        if (!isLoginWithLinkEnabled) {
            return t('Log in via a SMS OTP sent to your registered mobile number.');
        }
        return t('Login options include a link sent to your registered email address or a verification code sent to your registered mobile number.');
    };
    const toggleLoginWithOtp = () => {
        setLoginWithOtp(prevState => !prevState);
    };
    const handleLoginWithOtp = () => {
        toggleLoginWithOtp();
    };
    const toggleLoginWithLink = () => {
        setLoginWithLink(prevState => !prevState);
    };
    const handleLoginWithLink = () => {
        toggleLoginWithLink();
    };
    const canShowLoginByOptions = () => (isLoginWithOtpEnabled && customerPhoneNumber) || isLoginWithLinkEnabled;
    const canResetPassWord = () => !(showLoginWithOtp || showLoginWithLink);

    const [requestResetPassword, { loading, error }] = useMutation(REQUEST_RESET_PASSWORD, {
        onCompleted: () => {
            setShowSuccessMessage(true);
        },
    });

    const handleSubmit = async (event: { preventDefault: () => void }) => {
        event.preventDefault();
        let recaptchaToken: string | null = '';
        if (recaptchaRef.current) {
            recaptchaRef.current.reset();
            recaptchaToken = await recaptchaRef.current.executeAsync();
        }
        await requestResetPassword({
            variables: { email },
            context: {
                headers: {
                    'X-ReCaptcha': recaptchaToken,
                },
            },
        });
    };

    return (
        <>
            { (canShowLoginByOptions() && canResetPassWord()) && (
                <div className={styles.resetPasswordForm}>
                    <div className={styles.formHeader}>
                        <div className={styles.formTitle}>{t('Forgot Password')}</div>
                    </div>
                    <p className={styles.formDescription}>{ getLoginWithMessage() }</p>
                    {isLoginWithLinkEnabled && (
                        <div className={classes(styles.formField)}>
                            <button
                                data-action="checkout-method-login"
                                type="button"
                                className={fStyles.checkoutButton}
                                onClick={handleLoginWithLink}
                            >
                                <span className={classes(styles.fawrap)}>
                                    <i className="fa-solid fa-paper-plane" />
                                </span>
                                <span>{t('Email Me Link')}</span>
                            </button>
                        </div>
                    )}
                    {(isLoginWithOtpEnabled && customerPhoneNumber) && (
                        <div className={classes(styles.formField)}>
                            <button
                                data-action="checkout-method-login"
                                type="button"
                                className={fStyles.loginOtpButton}
                                onClick={handleLoginWithOtp}
                            >
                                <span className={classes(styles.fawrap)}>
                                    <i className="fa-solid fa-mobile-screen" />
                                </span>
                                <span>{t(`Text Code To ${customerPhoneNumber}`)}</span>
                            </button>
                        </div>
                    )}
                </div>
            )}
            { showLoginWithLink &&
                <LoginWithLink />
            }
            { showLoginWithOtp &&
                <LoginWithOtp updateCart={updateCart} />
            }
            { showSuccessMessage && (
                <div className={styles.success}>
                    Your password reset request has been received. Please check your inbox
                </div>
            )}

            { (!canShowLoginByOptions() && !showSuccessMessage) && (
                <form onSubmit={handleSubmit} className={styles.resetPasswordForm}>
                    {error && <div className="error">{error.message}</div>}
                    <div className={classes(fStyles.formHeader)}>
                        <div className={fStyles.formTitle}>{t('Forgot Password')}</div>
                    </div>
                    <div className={classes(fStyles.formField)}>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder={t('Email')}
                            onChange={event => setEmail(event.target.value)}
                        />
                    </div>
                    {config?.recaptcha_sitekey && (
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={config?.recaptcha_sitekey}
                            size="invisible"
                        />
                    )}
                    <div className={classes(fStyles.formField)}>
                        <button type="button" className={fStyles.checkoutButton} onClick={handleSubmit} disabled={loading}>
                            {t('Reset My Password')}
                        </button>
                    </div>
                </form>
            )}
        </>
    );
};
