import React from 'react';
import { Divider } from 'ui/component/checkout/components/divider';
import { ProductInfoLine } from './product-info-line';
import styles from './style.css';

export const ProductInfo = ({ item }) => {
    const productColor = item?.configurable_options?.filter(option => option.option_label === 'Color')[0]?.value_label;
    const productSize = item?.configurable_options?.filter(option => option.option_label === 'Size')[0]?.value_label;
    const imageUrl = item?.configured_variant?.thumbnail?.url ?? item?.product?.thumbnail?.url;
    return (
        <div className={styles.productDetailsWrapper}>
            <div className={styles.productDetails}>
                <div className={styles.productInfo}>
                    <ProductInfoLine label="" value={item.product.namce} className={styles.productName} />
                    <ProductInfoLine label="item" value={item.product.sku} />
                    <ProductInfoLine label="color" value={productColor ?? ''} />
                    <ProductInfoLine label="size" value={productSize ?? ''} />
                    <ProductInfoLine label="qty" value={item.quantity} />
                </div>
                <div className={styles.productImage}>
                    <img src={imageUrl} alt={item.product.name} />
                </div>
            </div>
            <Divider />
        </div>
    );
};
