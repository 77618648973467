import React, { useEffect, useState } from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import { customerEmailVar, cartIdVar } from 'ui/page/checkout-page/checkout-state';
import { SEND_CUSTOMER_LOGIN_LINK } from 'graphql/customer';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

export const LoginWithLink = () => {
    const t = usePhraseTranslater();
    const email = useReactiveVar(customerEmailVar);
    const cartId = useReactiveVar(cartIdVar);
    const [showSuccessMessage, setShowSuccessMessage] = useState('');
    const [error, setError] = useState('');
    const [sendCustomerLoginLink] = useMutation(SEND_CUSTOMER_LOGIN_LINK, {
        variables: { email, redirect_path: 'checkout', cart_id: cartId },
        onError: (error) => {
            setError(error.message || t('Something went wrong please try again.'));
            setShowSuccessMessage('');
        },
        onCompleted: (data) => {
            const message = data?.sendCustomerLoginLink || '';
            if (message !== '') {
                setError('');
                setShowSuccessMessage(data?.sendCustomerLoginLink);
            }
        },
    });

    const handleResendLink = () => {
        sendCustomerLoginLink();
    };
    useEffect(() => {
        sendCustomerLoginLink();
    }, [email]);
    return (
        <>
            <div className={styles.loginByLink}>
                {error && <div className={styles.error}>{t(error)}</div>}
                { showSuccessMessage && (
                    <>
                        <p
                            onClick={handleResendLink}
                            className={styles.confirmation}
                            role="presentation"
                        >
                            <span>{t(showSuccessMessage)}</span>
                            {'\n'}
                            <span className={styles.resendLink}>
                                {t('If you still haven\'t received it, click here to resend.')}
                            </span>
                        </p>
                    </>
                )}
            </div>
        </>
    );
};
